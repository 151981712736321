<template>
  <div>
    <empty-list
      v-if="!hasSnapshot && !loading"
      text="Ainda não há informações sobre a consulta de crédito"
    >
      <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
        <b-icon icon="arrow-down-up" class="mr-2" />
        <span>Sincronizar agora</span>
      </b-button>
    </empty-list>

    <div v-else>
      <div class="d-flex align-items-center justify-content-start">
        <small class="mr-2">
          Ultima atualização {{ response.updated_at | dateFull }}
        </small>
        <unicon
          v-if="is_provider && enabled"
          name="redo"
          @click="handleSyncronize()"
          class="pointer small"
          style="font-size: 12px"
          height="16"
          width="16"
        />
      </div>
      <div
        ref="chatScore"
        class="mx-auto w-100 text-center my-4"
        id="chart-score"
      />

      <div class="text-center mb-3">
        <h5>{{ this.score.message }}</h5>
      </div>

      <cards-list grid="4" gap="12" responsive="2">
        <b-card
          no-body
          class="py-2 px-2 text-center"
          :border-variant="pendency.has_pendency ? 'danger' : 'success'"
          v-for="(pendency, index) in creditInquiry.financial_pendencies"
          :key="index"
        >
          <span
            class="d-block"
            :class="pendency.has_pendency ? 'text-danger' : 'text-success'"
          >
            {{ pendency.message }}
          </span>
        </b-card>
      </cards-list>
      <div class="mt-4">
        <strong class="d-block mb-3">Dívidas</strong>
        <b-table striped :items="protests" :fields="fields" small />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { chartOptions } from "./chartOptions";
import { formatMoney } from "@/helpers/formatMoney";
const GaugeChart = require("gauge-chart");

export default {
  data() {
    return {
      service_id: "",
      provider_id: "",
      hasSnapshot: false,
      enabled: false,
      loading: false,
      creditInquiry: [],
      response: {},
      score: {},
      chartOptions,
      fields: [
        {
          key: "enterprise",
          label: "Empresa",
          sortable: true,
          colspan: 5,
        },
        {
          key: "value",
          label: "Valor da parcelas restantes",
          sortable: true,
          formatter: (value) => formatMoney(value),
        },
        {
          key: "quantity",
          label: "Quantidade de parcelas",
          sortable: true,
        },
      ],
      protests: [],
    };
  },

  computed: {
    ...mapGetters(["user", "is_provider", "is_mobile", "is_dark"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    initPlugin(value) {
      let config = {
        needleColor: this.is_dark ? "white" : "black",
        chartWidth: this.is_mobile ? 340 : 400,
        needleStartValue: value,
        arcDelimiters: [20, 40, 60, 80],
        arcLabels: [200, 400, 600, 800],
        rangeLabel: ["0", "1000"],
        hasNeedle: true,
        arcColors: ["#f84438", "#ff5454", "#efd613", "#b7ec82", "#3dcc5b"],
        centralLabel: `${value * 10}`,
        needleValue: value,
      };

      const element = this.$refs.chatScore;
      element.innerHTML = "";
      GaugeChart.gaugeChart(element, config.chartWidth, config).updateNeedle(
        config.needleValue
      );
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "credit-inquiry",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        if (!response?.updated_at) {
          this.hasSnapshot = false;
        } else {
          const { data } = response;
          this.response = response;
          this.hasSnapshot = true;
          this.creditInquiry = data;
          this.protests = data.protests;
          this.score = data.score;
          this.initPlugin(this.score.factor / 10);
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "credit-inquiry",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getSnapshot();
  },
  watch: {
    is_mobile() {
      this.getSnapshot();
    },
  },
};
</script>
